<template lang="pug">
  .tabs-content.tabs-purse.wrapper_gray-border.pt-3
    .tabs
      input#tab1(:disabled="isHistoryWalletLoading").tab1(type='radio', name='tabs', checked='')
      label(
        v-loading="isWhiteListLoading",
        for='tab1',
        :class="{'tab-disabled': isWhiteListLoading}",
        title='Просмотр белого списка',
        @click="changeTab('whiteList')",
      ) Белый список

      input#tab2(:disabled="isWhiteListLoading").tab2(type='radio', name='tabs')
      label(
        v-loading="isHistoryWalletLoading",
        for='tab2',
        :class="{'tab-disabled': isHistoryWalletLoading}"
        title='Просмотр историй кошельков',
        @click="changeTab('historyWallet')"
      ) История кошельков
      TheWhiteListAddress(v-if="currentTab === 'whiteList'")
      TheWalletAddressHistory(v-if="currentTab === 'historyWallet'")
</template>

<script>
import {mapGetters} from "vuex";
import TheWhiteListAddress from "@/components/Wallet/WhiteList/TheWhiteListAddress";
import {USER_WALLET_ADDRESS_HISTORY, USER_WALLET_PAYOUT_LIST} from "@/store/actions/user";
import TheWalletAddressHistory from "@/components/Wallet/WhiteList/TheWalletAddressHistory";

export default {
  components: {TheWalletAddressHistory, TheWhiteListAddress},
  data() {
    return {
      currentTab: 'whiteList',
    }
  },
  computed: {
    ...mapGetters(['loading']),
    isWhiteListLoading() {
      return this.loading(USER_WALLET_PAYOUT_LIST) === 'loading';
    },
    isHistoryWalletLoading() {
      return this.loading(USER_WALLET_ADDRESS_HISTORY) === 'loading';
    },
  },
  methods: {
    changeTab(tab) {
      if (!this.isWhiteListLoading && !this.isHistoryWalletLoading) {
        this.currentTab = tab;
      }
    },
  }
}
</script>

<style scoped>
.tabs-content .tabs > section {
  display: block;
}
</style>

<template lang="pug">
  section.content-tab
    section.page-info.page-info_purse
      .page-info__container
        .page-info__active
          button.btn.btn__main(@click="isShowAddList = true") Добавить
        hr
        .page-white__list-no-list(v-if="!getUserPayOutList.length") Нет созданного списка
        .operation-block(v-for="item in getUserPayOutList" :key="item.id")
          .operation-block__arrow
          .operation-block__data
            span ID: {{ item.id }}
          .operation-block-list__block
            p.mb-0 Кошелек: {{ item.wallet_address }}
          .operation-block-list__action
            button.btn.btn__ghost_light.mt-0.mb-0.mr-1(v-if="!item.payout_auto" @click="confirmDeleteWalletAddress(item.id)") Удалить
            el-tooltip(
              v-if="item.payout_auto"
              effect="dark"
              placement="top"
              content="Невозможно удалить кошелек на котором добавлен автовывод"
            )
              span
                el-button(:disabled="item.payout_auto" type="danger" plain @click="confirmDeleteWalletAddress(item.id)") Удалить
        el-dialog(:visible.sync="isShowAddList" title="Добавить кошелек")
          button.el-dialog__headerbtn(type="button" aria-label="Close" @click="isShowAddList = false")
            i.el-dialog__close.el-icon.el-icon-close
          input(
            @input="checkWallet",
            v-model="address"
            placeholder="Адрес для кошелька",
            type="text",
          )
          .address-error(v-if="isError")
            span {{ errorText }}
          span(slot="footer")
            button.btn.btn__main(:disabled="isDisabled" @click="createWhiteList") Добавить
    Safety(
      :show-popup="showSafetyPopup"
      @close="closeSafety"
      @code="getCode"
      :error="safety_error"
      :type="safety_type"
      @resend="resendSafety"
    )
</template>

<script>
import {mapGetters} from "vuex";
import WAValidator from "wallet-validator";
import {
  USER_WALLET_ADD_PAYOUT,
  USER_WALLET_DELETE_PAYOUT,
  USER_WALLET_PAYOUT_LIST,
  USER_WALLET_VALIDATE,
} from "@/store/actions/user";
import Safety from "@/components/Common/Safety";
import errors from '@/components/mixins/errors';
import security from '@/components/mixins/security';

export default {
  components: {Safety},
  mixins: [
    errors,
    security
  ],
  data() {
    return {
      isShowAddList: false,
      address: '',
      errorText: '',
      isError: false,
      cryptoName: 'bitcoin',
      cryptoId: 1,
      google2fa_secret: '',
      showSafetyPopup: false,
      otp_secret: '',
      safety_error: '',
      safety_type: 'twoFa',
      addressID: null,
      isCreateWhiteList: false,
      isDeleteWhiteList: false,
    }
  },
  computed: {
    ...mapGetters(['getUserPayOutList', 'loading']),
    isLoading() {
      return this.loading(USER_WALLET_PAYOUT_LIST) === 'loading'
    },
    isDisabled() {
      return this.isError || !this.address.length
    }
  },
  watch: {
    address(val) {
      if (val) {
        this.withdrawCheck(val)
      }
    },
  },
  async mounted() {
    await this.getList()
  },
  methods: {
    async getList() {
      await this.$store.dispatch(USER_WALLET_PAYOUT_LIST)
    },
    getCode({code, type}) {
      if (this.isDeleteWhiteList) {
        if (type === 'otp') {
          this.otp_secret = code;
          this.deleteWhiteList(false, true);
        } else {
          this.google2fa_secret = code;
          this.deleteWhiteList(true);
        }
      } else if (this.isCreateWhiteList) {
        if (type === 'otp') {
          this.otp_secret = code;
          this.createWhiteList(false, true);
        } else {
          this.google2fa_secret = code;
          this.createWhiteList(true);
        }
      }
    },
    closeSafety() {
      this.showSafetyPopup = false;
      this.google2fa_secret = '';
      this.otp_secret = '';
      this.isCreateWhiteList = false;
      this.isDeleteWhiteList = false;
    },
    resendSafety() {
      this.safety_error = '';
      if (this.isDeleteWhiteList) {
        this.deleteWhiteList(false, true);
      } else if (this.isCreateWhiteList) {
        this.createWhiteList(false, true);
      }
    },
    handleError(error) {
      const safety_errors = this.haveSecurityError(error);
      if (safety_errors.have) {
        if (safety_errors.need_open && this.showSafetyPopup === false) {
          this.safety_type = safety_errors.type;
          this.isShowAddList = false;
          this.showSafetyPopup = true;
        } else {
          this.safety_error = safety_errors.message;
        }
      } else if (error && error.errors) {
        this.$message.error({
          dangerouslyUseHTMLString: true,
          message: this.formatErrors(error.errors),
        });
        this.closeSafety()
      } else {
        this.$message.error(error.message);
        this.closeSafety()
      }
    },
    createWhiteList(with_2fa = false, with_otp = false) {
      this.isCreateWhiteList = true;
      const data = {}
      data.wallet_address = this.address
      if (with_2fa === true) {
        data.google2fa_secret = this.google2fa_secret;
      }
      if (with_otp === true) {
        data.otp = this.otp_secret;
      }
      this.safety_error = ''
      this.$store.dispatch(USER_WALLET_ADD_PAYOUT, data)
        .then(() => {
          this.address = ''
          this.isShowAddList = false
          this.closeSafety();
          this.getList()
        }).catch((error) => {
        this.handleError(error)
      });
    },
    confirmDeleteWalletAddress(id) {
      this.$confirm(
        'Вы уверены, что хотите удалить адрес кошелька?',
        'Внимание!',
      ).then(() => {
        this.addressID = id
        this.deleteWhiteList();
      })
    },
    deleteWhiteList(with_2fa = false, with_otp = false) {
      this.isDeleteWhiteList = true
      const data = {}
      if (with_2fa) {
        data.google2fa_secret = this.google2fa_secret;
      }
      if (with_otp) {
        data.otp = this.otp_secret;
      }
      this.safety_error = ''
      this.$store.dispatch(USER_WALLET_DELETE_PAYOUT, {id: this.addressID, data})
        .then(() => {
          this.getList()
          this.closeSafety();
          this.$alert('Успешно удалено', {
            showClose: true,
            type: 'success'
          });
        }).catch((error) => {
        this.handleError(error)
      });
    },
    checkWallet() {
      let network = process.env.NODE_ENV === 'production' ? 'prod' : 'testnet';
      if (!WAValidator.validate(this.address, this.cryptoName, network)) {
        this.isError = true;
        this.errorText = 'Недопустимый формат адреса';
      } else {
        this.isError = false;
        this.errorText = '';
      }
    },
    async withdrawCheck(withdraw) {
      if (!this.isError) {
        await this.$store.dispatch(USER_WALLET_VALIDATE, {
          address: withdraw,
          crypto_currency_id: this.cryptoId
        })
      }
    },
  }
}
</script>

<style scoped>
.page-info__active {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.page-white__list-no-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.operation-block-list__block {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  text-align: center;
}

.operation-block-list__block p {
  font-size: 16px;
  width: 100%;
}

.address-error {
  font-size: 14px;
  line-height: 20px;
  color: red;
  height: 30px;
  display: flex;
  align-items: center;
}

/*input {*/
/*  width: 100%;*/
/*  background: $blue100;*/
/*  border: 1px solid $blue300;*/
/*  font-size: 16px;*/
/*  color: #353535;*/
/*  border-radius: 6px 0 0 6px;*/
/*  height: 50px;*/
/*  padding-left: 20px;*/
/*  padding-right: 10px;*/
/*}*/
</style>

<template lang="pug">
  section.content-tab
    section.page-info.page-info_purse(v-loading="isLoading")
      .page-info__container
        hr
        .page-history__list-no-list(v-if="!getUserWalletAddressHistory.length") Отсутствует история кошельков
        .operation-block(v-for="item in getUserWalletAddressHistory" :key="item.id")
          .operation-block__arrow
          .operation-block-list__block
            p.mb-0 Кошелек: {{ item.wallet }}
    .page-history__list-pagination
      el-pagination(
        v-if='hasPagination'
        :total='getUserWalletAddressHistoryPagination.total',
        :page-size='parseInt(getUserWalletAddressHistoryPagination.per_page)',
        :current-page='getUserWalletAddressHistoryPagination.current_page',
        :disabled='isLoading'
        background,
        layout='prev, pager, next',
        @current-change='changeCurrent',
      )
</template>

<script>
import {mapGetters} from "vuex";
import {USER_WALLET_ADDRESS_HISTORY} from "@/store/actions/user";

export default {
  data() {
    return {
      per_page: 15,
    }
  },
  computed: {
    ...mapGetters([
      'getUserWalletAddressHistory',
      'getUserWalletAddressHistoryPagination',
      'loading',
    ]),
    isLoading() {
      return this.loading(USER_WALLET_ADDRESS_HISTORY) === 'loading'
    },
    hasPagination() {
      return !!this.getUserWalletAddressHistoryPagination && this.getUserWalletAddressHistoryPagination.total_pages > 1;
    },
  },
  async mounted() {
    await this.getList()
  },
  methods: {
    async getList(page = 1) {
      await this.$store.dispatch(USER_WALLET_ADDRESS_HISTORY, {crypto_currency_id: 1, page, per_page: this.per_page})
    },
    changeCurrent(page) {
      this.getList(page)
    },
  }
}
</script>

<style scoped>
.page-history__list-no-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.operation-block-list__block {
  padding-left: 15px;
}

.operation-block-list__block p {
  font-size: 16px;
  width: 100%;
}
.page-history__list-pagination {
  text-align: center;
  margin: 10px;
}
</style>

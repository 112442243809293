<template lang="pug">
  .main-content.layout-main__centering.container.pb-5(
    element-loading-text='Загрузка данных...',
  )
    WhiteListContainer
</template>

<script>
import WhiteListContainer from "@/components/Wallet/WhiteList/index.vue";

export default {
  name: "WhiteList",
  components: {
    WhiteListContainer
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.operation-block-history__block {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  text-align: center;
}
.operation-block-history__block p {
  font-size: 16px;
  width: 100%;
}
.section-block {
  border-bottom: 1px solid #CFE2D6 !important;
  display: block;
  padding: 0 10px;
  background: #fff;
  -webkit-box-shadow: 0 2px 9px rgb(0 0 0 / 7%);
  box-shadow: 0 2px 9px rgb(0 0 0 / 7%);
}
.address-error {
  font-size: 14px;
  line-height: 20px;
  color: red;
  height: 30px;
  display: flex;
  align-items: center;
}
input {
  width: 50%;
  background: $blue100;
  border: 1px solid $blue300;
  font-size: 16px;
  color: #353535;
  border-radius: 6px 0 0 6px;
  height: 50px;
  padding-left: 20px;
  padding-right: 10px;
}
</style>